$theme-colors: (
  "primary":  #283479,
  "secondary": #008ECE
);

$primary: #283479;
$secondary:  #008ECE;



body, #root, .container-main {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}

body {
  background-color: white;
}

.dept-buttons-container{
  padding: 1em;

  .row {
    padding-left: 2em;
    padding-right: 2em;
  }
}

.footer,
.footer a {
  display: block;
  bottom: 0;
  background-color: #283479;
  color: white;
}

.ftr-menu,
.ftr-menu a {
  background-color: $secondary;
}

.global-nav {
  width: 100%;
  height: auto;
  min-height: 3.52rem;
  background-color: rgb(40, 52, 121);
  color: #fff;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  
  .container-fluid {
    position: relative;
    min-height: 3.52rem;
  }
  
  .navbar-toggler {
    background-color: $secondary;
    box-shadow: 0 0 0 0rem $secondary;
    right: 1rem;
    top: 0.5rem;
    position: absolute;
    justify-self: flex-start;
  }
  #responsive-navbar-nav.show,
  #responsive-navbar-nav.collapsing
   {
    padding-left: 1rem;
  }
  a {
    font-size: 1rem!important;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    padding-left: 0rem;
    padding-right: 0rem;
    margin-right: 1.4rem;
    text-align: left;
  }
  a > svg,
  a > i {
    height: 1.618rem;
    white-space: nowrap;
    margin-right: .6rem;
  }

  a > svg {
    width: 2.618rem;
  }

  a > i {
    width: 2.618rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  a > i.LocationIcon {
    background-image: url('../assets/icons/Locations_05-24-21-outline-blue.png');
  }

  a:hover > i.LocationIcon {
    background-image: url('../assets/icons/Locations_05-24-21-outline-white.png');

  }

  a > i.ProviderIcon {
    background-image: url('../assets/icons//Providers_05-24-21-outline-blue.png');
  }

  a:hover > i.ProviderIcon {
    background-image: url('../assets/icons/Providers_05-24-21-outline-white.png');

  }

  .st0 {
    fill: none;
    stroke: #098ECE;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st1 {
    fill: #098ECE;
  }

  a:hover .st0,
  a:hover .st1 {
    color: #fff;
    stroke: #fff;
  }

  a:hover .st1 {
    fill: #fff;
  }
}

h2.breadCrumbs span {
  text-transform: uppercase;
}

h3.breadCrumbs span {
  text-transform: capitalize;
}

h2.breadCrumbs span:hover,
h3.breadCrumbs span:hover {
  text-decoration: underline;
  cursor: pointer;
}
@media (min-width: 768px) {
  .global-nav {
    height: 3.52rem;
    padding-left: 2.5rem; 
  padding-right: 3.3rem;
  }
}

@media (min-width: 576px){
  h2.breadCrumbs,
  h3.breadCrumbs {
    text-align: right;
  }
  #ftr-privacy-policy {
    display: flex;
    justify-content: flex-end;
  }
}

// @media (min-width: 816px){
//   iframe {
//     width: 45.75rem !important;
//   }
// }

@media (max-width: 575px){
  .footer {
    p {
      text-align: center !important;
      font-size: 12px;
    }
    h2.breadCrumbs {
      text-align: left;
    }
    .row > div {
      display: flex;
      justify-content: center;
    }
  }

  .dept-buttons-container > .dept-buttons-row > div:last-child {
    padding-bottom: .5rem !important;
  }

  .dept-buttons-container > .dept-buttons-row > div:first-child {
    padding-top: .5rem !important;
  }

  #root > div > div.body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  #ChristieClinicLogo {
    max-height: 55px !important;
  }

  // #root > div.container-main > #header-container > #header-row {
  //   // padding-left: 0px !important;
  //   // padding-right: 0px !important;
  // }
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
  align-self: center;
}

